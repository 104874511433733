import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)



const routes = [{
        path: '/',
        redirect: '/home',
    },
    {
        path: '/home',
        name: 'home',
        // component: () => import( /* webpackChunkName: "about" */ '../views/HomeView.vue')
        // component: () => import( /* webpackChunkName: "about" */ '../views/testSystem/home.vue')
        component: () => import( /* webpackChunkName: "about" */ '../views/home/home.vue'),
    },
    {
        path: '/login',
        name: 'login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/AboutView.vue'),
    },
    {
        path: '/show',
        name: 'show',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/ShowView.vue'),
    },
    {
        path: '/testindex',
        name: 'testindex',
        component: () => import( /* webpackChunkName: "main" */ '../views/testSystem/testindex.vue'),
        meta: {
            isAuthenticated: true, //表示进入这个路由是需要登录的
        }
    },
    {
        path: '/main',
        name: 'main',
        component: () => import( /* webpackChunkName: "main" */ '../views/testSystem/index.vue'),
        meta: {
            isAuthenticated: true, //表示进入这个路由是需要登录的
        }

        // 	// 文件管理
        // 	{
        // 		path: '/folder',
        // 		name: 'folder',
        // 		component: () => import( /* webpackChunkName: "main" */ '../views/fileManage/index.vue')
        // 		// component: () => import( /* webpackChunkName: "main" */ '../views/MainView.vue')
        // 	},
        // 	{
        // 		path: '/fupload',
        // 		name: 'fupload',
        // 		component: () => import( /* webpackChunkName: "upload" */'../views/fileManage/Upload.vue')
        // 	},
        // ]
    },
    // 	// 文件管理
    {
        path: '/folder',
        name: 'folder',
        component: () => import( /* webpackChunkName: "main" */ '../views/fileManage/index.vue')
    },
    {
        path: '/testupload',
        name: 'testupload',
        component: () => import( /*  webpackChunkName: "main" */ '../views/fileManage/testuploadimage.vue')
    },
    {
        path: '/filepreview',
        name: 'filepreview',
        component: () => import( /*  webpackChunkName: "main" */ '../views/filePreview/index.vue')
    },
	{
	    path: '/filelist',
	    name: 'filelist',
	    component: () => import( /*  webpackChunkName: "main" */ '../views/filePreview/list.vue')
	},
    // 项目管理
    {
        path: '/project',
        name: 'project',
        component: () => import( /* webpackChunkName: "main" */ '../views/projectManage/index.vue')
    },
    {
        path: '/ProjectManagerSystem',
        name: 'ProjectManagerSystem',
        component: () => import( /* webpackChunkName: "main" */ '../views/projectManage/unniindex.vue')
    },
    {
        path: '/addpro',
        name: 'addpro',
        component: () => import( /* webpackChunkName: "main" */ '../views/projectManage/addProjcet.vue')
    },
    // 新闻页
    {
        path: '/news1',
        name: 'news1',
        component: () => import( /* webpackChunkName: "main" */ '../views/newsview/NewsView.vue')
    },
    {
        path: '/news2',
        name: 'news2',
        component: () => import( /* webpackChunkName: "main" */ '../views/newsview/NewsTwo.vue'),
    },
    {
        path: '/news3',
        name: 'news3',
        component: () => import( /* webpackChunkName: "main" */ '../views/newsview/NewsThree.vue'),
    },
    {
        path: '/news4',
        name: 'news4',
        component: () => import( /* webpackChunkName: "main" */ '../views/newsview/NewsFour.vue'),

    },
    {
        path: '/news5',
        name: 'news5',
        component: () => import( /* webpackChunkName: "main" */ '../views/newsview/NewsFive.vue'),
    },
    // 最新消息
    {
        path: '/newsthirty',
        name: 'newsthirty',
        component: () => import( /* webpackChunkName: "main" */ '../views/newsview/lastnews/ThirtyNews.vue'),
    },
    {
        path: '/newsthree',
        name: 'newsthree',
        component: () => import( /* webpackChunkName: "main" */ '../views/newsview/lastnews/ThreeNews.vue'),
    },
    {
        path: '/newssix',
        name: 'newssix',
        component: () => import( /* webpackChunkName: "main" */ '../views/newsview/lastnews/SixNews.vue'),
    },
    // 11月新闻
    {
        path: '/fifth',
        name: 'fifth',
        component: () => import( /* webpackChunkName: "main" */ '../views/newsview/sepNews/FifthSeb.vue'),
    },
    {
        path: '/sebtaber',
        name: 'sebtaber',
        component: () => import( /* webpackChunkName: "main" */ '../views/newsview/sepNews/SebtaberTwo.vue'),
    },
    {
        path: '/sebo',
        name: 'spbo',
        component: () => import( /* webpackChunkName: "main" */ '../views/newsview/sepNews/SeboNews.vue'),
    },
    {
        path: '/sebt',
        name: 'spbt',
        component: () => import( /* webpackChunkName: "main" */ '../views/newsview/sepNews/SebtNews.vue'),
    },
    {
        path: '/sebth',
        name: 'spbth',
        component: () => import( /* webpackChunkName: "main" */ '../views/newsview/sepNews/SepthNews.vue'),
        // meta: {
        // 	keepAlive: true,
        // 	isBack: false,//设置isBack属性，用来判断是否从详情页返回
        // }
    },
    // DecNews
    {
        path: '/decnoa',
        name: 'decnoa',
        component: () => import( /* webpackChunkName: "main" */ '../views/newsview/decnews/DecNoa.vue'),
    },
    {
        path: '/decnob',
        name: 'decnob',
        component: () => import( /* webpackChunkName: "main" */ '../views/newsview/decnews/DecNob.vue'),
    },
    {
        path: '/decnoc',
        name: 'decnoc',
        component: () => import( /* webpackChunkName: "main" */ '../views/newsview/decnews/DecNoc.vue'),
    },
    {
        path: '/decnod',
        name: 'decnod',
        component: () => import( /* webpackChunkName: "main" */ '../views/newsview/decnews/DecNod.vue'),
    },
    {
        path: '/decnoe',
        name: 'decnoe',
        component: () => import( /* webpackChunkName: "main" */ '../views/newsview/decnews/DecNoe.vue'),
    },
    {
        path: '/decnof',
        name: 'decnof',
        component: () => import( /* webpackChunkName: "main" */ '../views/newsview/decnews/DecNof.vue'),
    },
    {
        path: '/decnog',
        name: 'decnog',
        component: () => import( /* webpackChunkName: "main" */ '../views/newsview/decnews/DecNog.vue'),
    },
    {
        path: '/decnoh',
        name: 'decnoh',
        component: () => import( /* webpackChunkName: "main" */ '../views/newsview/decnews/DecNoh.vue'),
    },

    // 通知
    {
        path: '/notification',
        name: 'notification',
        component: () => import( /* webpackChunkName: "main" */ '../views/notification/NotifiCation.vue'),
    },
    // 奖项
    {
        path: '/prize',
        name: 'prize',
        component: () => import( /* webpackChunkName: "main" */ '../views/newsview/herizon/Prize.vue'),
    },
    // test页面
    {
        path: '/test',
        name: 'test',
        component: () => import( /* webpackChunkName: "main" */ '../views/test/TestVue.vue'),
    },
    // 更多
    {
        path: '/more',
        name: 'more',
        component: () => import( /* webpackChunkName: "main" */ '../views/newsview/NewsMore.vue'),
        meta: {
            keepAlive: true,
            // isBack: false,//设置isBack属性，用来判断是否从详情页返回
        }
    },
    // event事件页面
    {
        path: '/event',
        name: 'event',
        component: () => import( /* webpackChunkName: "about" */ '../views/testSystem/event.vue')
    },
    // event事件
    // 任务隐藏页面
    {
        path: '/dask',
        name: 'dask',
        component: () => import( /* webpackChunkName: "scroll" */ '../views/publicView/dask/dask.vue')
    },
    // 新闻上传页面
    {
        path: '/newsUp',
        name: 'newsUp',
        component: () => import( /* */ '../views/newsSystem/uploadnews/UploadNews.vue')
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
    linkActiveClass: 'active', //linkeActivateClass属性。这个属性的作用是定义被激活的链接元素的类名。
})

export default router

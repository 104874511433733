<template>
	<div id="app">
		<!-- 缓存想要缓存的页面，实现后退不刷新 -->
		<keep-alive>
			<router-view></router-view>
		</keep-alive>
		<!-- <keep-alive >
			<router-view   :key="key" v-if="$route.meta.keepAlive"></router-view>
		</keep-alive>
		<router-view   :key="key"  v-if="!$route.meta.keepAlive"></router-view> -->
	</div>
</template>
<script>
	export default { 
		name: 'App',
		// 进入home页面之后刷新
		// watch: {
		// 	$route(to, from) {
		// 		if(to.name === "home" && from.name) {
		// 			this.$router.go(0);
		// 		}
		// 	}
		// },
		data() {
			return {
			}
		},
		// 增加一个不同:key值，这样vue就会识别这是不同的<router-view>了
		computed: {
			key() {
				return this.$route.path + Math.random();
			}
		},
	}
</script>

<style lang="scss" scoped>
	body,html {
		background-color: #333 !important;
	}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
	BootstrapVue,
	IconsPlugin
} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import {
	config
} from '@vue/test-utils'
import axios from 'axios'
//导入
import cookie from "js-cookie"

// mock  上线时删掉mock相关内容
/*
 * 如果不想使用mock-server
 * 可以使用Mockjs作为模拟api
 * 可以执行：mockXHR()
 * 目前Mockjs将在生产环境中使用；
 * 在上线前删除
*/
import './mock/index.js'
// import  { mockXHR } from './mock'
// if(process.env.NODE_ENV === 'production') {
// 	mockXHR()
// }




// element ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'


// video-player
import Videoplayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'

Vue.use(Videoplayer)
Vue.use(ElementUI)


//将cookie转换为 vue 原型
Vue.prototype.Cook = cookie

Vue.prototype.$ajax = axios
Vue.config.productionTip = false

// 响应式
Vue.use(BootstrapVue)
// 图标
Vue.use(IconsPlugin)


new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')

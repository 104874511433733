// 首先导入Mock
import Mock from 'mockjs'

import projectApi from '../mock/projectadd/addpro'

Mock.mock('/api/addpro', 'post', projectApi.createpro) //添加项目接口

Mock.mock('/api/updatepro', 'post', projectApi.Updatepro) //更新项目接口
Mock.mock('/api/deletepro', 'post', projectApi.Deletepro) //删除项目接口
Mock.mock(/\/api\/infolist/, 'get', projectApi.Getproepro) //查询项目接口

//1、 获取项目列表接口 index.vue
Mock.mock('/api/prolist', 'get', {
  status: 200,
  message: '项目列表获取成功',
  'data|10': [{
    id: '@increment(1)',
    pname: '@cword(2,9)',
    stardate: '2023-01-7',
    delaydate: '2023-01-15',
    proleader: '@cname(4)',
    prodescribe: '@cparagraph(1,3)', //段落1-3
    promember: '@cname(3)', //项目成员名称|次数；
  }],
  total: 10
})

import Mock from  'mockjs'
 
 // get请求从config.url获取参数，post从config.body中获取参数
 function param2Obj(url) {
	 const search = url.split('?')[1]
	 // console.log('param2Objsearch',search)
	 if(!search) {
		 return {
			 
		 }
	 }
	 return JSON.parse( 
	'{"' + 
	decodeURIComponent(search)
	    .replace(/"/g, '\\"')
	    .replace(/&/g, '","')
	    .replace(/=/g, '":"') +
	' "}'
 )
 
 }
 
 
 
 
 
 let List = []
 const count = 20
 for( let i=0;i <count; i++) {
	 List.push(
		Mock.mock({
			id: '@increment(1)',
		    pname: '@cword(2,9)',
				// start_enddate: '2023-01-08',
		    stardate: '2023-01-7',
		    delaydate: '2023-01-15',
				 proleader: '@cname(4)',
				 prodescribe: '@cparagraph(1,3)',//段落1-3
				 promember: '@cname(3)',//项目成员名称|次数；
		})
	 )
 }
 
 // Mock.mock('/api/addpro', 'post',projectApi.createpro)//添加项目接口
  
 //  Mock.mock('/api/updatepro', 'post',projectApi.Updatepro)//更新项目接口
 //  Mock.mock('/api/deletepro', 'post',projectApi.Deletepro)//删除项目接口
 //  Mock.mock(/\/api\/infolist/, 'get',projectApi.Getproepro)//查询项目接口
  
 
 export default {
	 /*
	 获取列表
	 @param pname, page, limit
	 @return {{status: number, total: number, data: *[]}}
	 */
	Getproepro: config => {
		console.log(config)
		const {
			keyWord,
			page = 1,
			limit = 10,
			id,
			pname,
			// start_enddate,
			stardate,
			delaydate,
			proleader,
			prodescribe,
			promember,
		} = param2Obj(config.url)
		const mockList = List.filter(project => {
			return !(keyWord && project.pname.indexOf(keyWord) === -1 && project.prodescribe.indexOf(keyWord) === -1)
		})
		const  pageList = mockList.filter((item,index) => index <limit * page && index >= limit * (page - 1))
		return {
			status: 200,
			count: mockList.length,
			List : pageList
		}
	
	},
	
	/**
	 * 增加项目
	 * @param  
	 * @return {{ status: number, data: { message: string}}}
	 * pname: '
stardate: '
delaydate: 
 proleader:
 prodescrib
 promember:
	 */
	createpro: config => {
		console.log(config)
		const {
			pname,
			// start_enddate,
			stardate,
			delaydate,
			proleader,
			prodescribe,
			promember,
		} = JSON.parse(config.body)
		console.log(JSON.parse(config.body))
		List.unshift({
			id:Mock.Random.id(),
			pname: pname,
			// start_enddate,
			proleader:proleader,
			stardate:stardate,
			delaydate:delaydate,
			prodescribe: prodescribe,
			promember: promember,
		})
		return {
			status: 200,
			data: {
				message: '添加成功'
			}
		}
	},
	/**
	 * Deletepro
	 * 删除用户
	 * @param id
	 * @return {*}
	 */
	Deletepro: config => {
			// console.log('del',config.body)
			// const {
			// 	id
			// } = param2Obj(config.body)
			// console.log('列表中要删除的id',config.body)
			// // const body = JSON.parse(config.body)
			// // console.log('列表中要删除的idbody',body)
			// if(!id) {
			// 	return {
			// 		status: -200,
			// 		message: '参数不正确'
			// 	}
			// } else {
			// 	List = List.filter( u => u.id !== id)
			// 	return {
			// 		status: 200,
			// 		message: '删除成功'
			// 	}
			// }
			const {
				id,
				pname,
				stardate,
				delaydate,
				proleader,
				prodescribe,
				promember,
		} = JSON.parse(config.body)
			console.log(config)
			let List = JSON.parse(config.body)
			console.log('List',List)
			const index = List.findIndex((item) => {
			    return item.id === body.id;
			});
			List.splice(index, 1);
			console.log(index);
			return {
			    status: 200,
			    message: "添加成功",
			    list: List,
			    total: List.length,
			};
	},
	
	/**
	 * 修改用户
	 * @param  id, stardate: '', delaydate: '', proleader:'', prodescribe:','promember:''
	 * @return {{status: number,data: {message: string}}}
	 */
	Updatepro: config => {
		console.log('update', config)
		const {
			id,
			pname,
			stardate,
			delaydate,
			proleader,
			prodescribe,
			promember
		} = JSON.parse(config.body)
		List.some(u => {
			if(u.id === id) {
				u.panme = pname,
				// u.start_enddate = start_enddate,
				u.stardate=stardate,
				u.delaydate=delaydate,
				u.proleader=proleader,
				u.prodescribe=prodescribe,
				u.promember=promember
				return true
			}
		})
		return {
			status: 200,
			data: {
				message: '编辑成功'
			}
		}
	},
	
	
	
	
	
	
	
 }
 
 